<template>
    <div >
      <div class="validationAdress">
        <h4 style="text-align: center">Validação de Moradas</h4>
        <label for="">Tipo de Validação</label> <br />
        <select
          class="form-select form-select"
          aria-label=".form-select-lg example"
          v-model="status"
          @change="validationAddressStatus()"
        >
          <option value="off">Desligado</option>
          <option value="manual">Manual</option>
          <option value="logistica">Logistica</option>
        </select>
      </div>
      <hr />
      <div class="container">
        <table class="table">
          <tr>
            <th>Id</th>
            <th>Email</th>
            <th>Desconto</th>
          </tr>
          <tbody v-for="disc in allDiscounts" :key="disc.id">
            <tr>
              <td>{{ disc.id }}</td>
              <td>{{ disc.email }}</td>
              <td style="width: 20%">
                <input type="number" style="width: 30%" v-model="disc.discount" @change="editDiscount(disc)" />%
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    data: function(){
        return{
            status: '',
            allDiscounts: [],
        }
    },
    methods: {
        async loadData(){
            await this.getDiscount();
            await this.validationAddressGet();
        },
        async validationAddressGet() {
            try {
                await this.$store.dispatch('addressValidation');
                console.log(this.getValidationAddress);
                this.status = this.getValidationAddress.status;
            } catch (err) {
                alert(err);
            }
        },
        async validationAddressStatus() {
            try {
                await this.$store.dispatch('addressValidationStatus', {
                status: this.status,
                });
                this.$swal({
                toast: true,
                position: 'top-end',
                icon: 'success',
                title: `Validação de Moradas Atualizada!`,
                showConfirmButton: false,
                timer: 1500,
                });
            } catch (err) {
                alert(err);
            }
        },
        async editDiscount(data) {
            console.log(data);
            try {
                await this.$store.dispatch('updateDiscountClients', {
                id: data.id,
                discount: data.discount,
                });
                this.$swal({
                toast: true,
                position: 'top-end',
                icon: 'success',
                title: `Desconto atualizado`,
                showConfirmButton: false,
                timer: 1500,
                });
                this.getDiscount();
            } catch (error) {
                console.log(error);
            }
        },
        async getDiscount() {
            try {
                await this.$store.dispatch('allClientsDiscounts');
                this.allDiscounts = this.getclientDiscount;
                console.log(this.allDiscounts);
            } catch (error) {
                console.log(error);
            }
        },
    },
    created(){
        this.loadData();
    },
    computed: {
        ...mapGetters(['getclientDiscount', 'getValidationAddress'])
    }
}
</script>

<style>
.container {
  text-align: center;
}
.validationAdress {
  text-align: center;
  margin: 0px 600px 0px 600px;
}
h2 {
  margin-top: 20px;
  margin-bottom: 50px;
  text-align: center;
}
table {
  margin: 1em 0;
  border-collapse: collapse;
  border: 0.1em solid #d6d6d6;
  text-align: center;
}
th,
td {
  padding: 0.25em 0.5em 0.25em 1em;
  vertical-align: text-top;
  text-align: center;
  text-indent: -0.5em;
}

th {
  vertical-align: bottom;
  background-color: #666;
  color: #fff;
}

tr:nth-child(even) th[scope='row'] {
  background-color: #f2f2f2;
}

tr:nth-child(odd) th[scope='row'] {
  background-color: #fff;
}

tr:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.05);
}

tr:nth-child(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

th:nth-of-type(3),
td:nth-of-type(3) {
  text-align: center;
}

#updateValues {
  margin-left: 5px;
  cursor: pointer;
}
#updateValues:hover {
  cursor: pointer;
}

.shipping {
  margin: 0px 5px 0px 5px;
  background-color: #8fb1cd;
  border-color: #8fb1c1;
}
.shipping:hover {
  background-color: #7097b4;
  border-color: #7097b4;
}

.autoPrice {
  margin: 0px 5px 0px 5px;
  background-color: #7097b4;
  border-color: #7097b4;
}
.autoPrice:hover {
  background-color: #527d9c;
  border-color: #527d9c;
}
.table {
  text-align: center;
}
</style>